import React from 'react';
import logo from '../assets/images/logo.png';
const Logo = () => {
    return (
        <div className="logo">
            <img src={logo} alt="vector." />
        </div>
    );
};

export default Logo;