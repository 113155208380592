import React, { useEffect } from 'react';
import '../App.css';

const ServicesSection = () => {
  // Animation on scroll setup
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
        }
      });
    }, { threshold: 0.1 });

    const serviceElements = document.querySelectorAll('.service-card');
    serviceElements.forEach(el => observer.observe(el));

    return () => {
      serviceElements.forEach(el => observer.unobserve(el));
    };
  }, []);

  const services = [
    {
      id: 1,
      title: "CRM Implementation",
      description: "Custom CRM solutions tailored to your business needs. We help you streamline operations and improve customer relationships.",
      icon: "💼"
    },
    {
      id: 2,
      title: "Automation Setup",
      description: "Save time and reduce errors with powerful workflow automations that handle repetitive tasks efficiently.",
      icon: "⚙️"
    },
    {
      id: 3,
      title: "Marketing Funnels",
      description: "Convert more leads with strategic marketing funnels designed to nurture prospects through their buyer journey.",
      icon: "📈"
    },
    {
      id: 4,
      title: "Client Management",
      description: "Organize client information, track interactions, and provide exceptional service with our management tools.",
      icon: "👥"
    },
    {
      id: 5,
      title: "Custom Web Development",
      description: "We create responsive, user-friendly sites that convert visitors into customers.",
      icon: "🌐"
    },
    {
      id: 6,
      title: "SEO",
      description: "Optimize your website for search engines to increase visibility and drive organic traffic.",
      icon: "🔍"
    }
  ];

  return (
    <section id="services" className="services-section">
      <div className="section-container">
        <div className="section-header">
          <div className="section-title-container">
            <h2 className="section-title">Our Services</h2>
            <div className="title-underline"></div>
          </div>
          <p className="section-subtitle">Streamline your business operations with our comprehensive CRM solutions</p>
        </div>
        
        <div className="services-grid">
          {services.map((service) => (
            <div className="service-card" key={service.id}>
              <div className="service-icon">{service.icon}</div>
              <h3 className="service-title">{service.title}</h3>
              <p className="service-description">{service.description}</p>
              <a href="https://site.vectorsoftwaresolutions.com" className="service-link" target="_blank" rel="noopener noreferrer">
                Learn More
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;