import React, { useEffect, useRef } from 'react';
import '../App.css';
import Typer from './typer';

const HeroSection = () => {
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const ctaRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    // Add animation classes after component mounts
    setTimeout(() => {
      if (titleRef.current) titleRef.current.classList.add('animated');
      
      setTimeout(() => {
        if (subtitleRef.current) subtitleRef.current.classList.add('animated');
        
        setTimeout(() => {
          if (ctaRef.current) ctaRef.current.classList.add('animated');
          if (imageRef.current) imageRef.current.classList.add('animated');
        }, 200);
      }, 200);
    }, 100);
  }, []);

  return (
    <section id="home" className="hero-section">
      <div className="hero-container">
        <div className="hero-content">
          <h1 ref={titleRef} className="hero-title">
            Streamline Your Business with <span className="highlight">Advanced CRM</span> Solutions
          </h1>
          <p ref={subtitleRef} className="hero-subtitle">
            Optimize customer relationships, automate workflows, and boost productivity with our intuitive CRM systems
          </p>
          <div ref={ctaRef} className="hero-cta">
            <a href="https://site.vectorsoftwaresolutions.com" className="primary-btn" target="_blank" rel="noopener noreferrer">
              Explore Our System
            </a>
            <a href="#services" className="secondary-btn">
              View Services
            </a>
          </div>
        </div>
        
        <div ref={imageRef} className="hero-image">
          <div className="image-shape"></div>
          <div className="image-container">
            <div className="dashboard-mockup">
              <div className="dashboard-element element-1"></div>
              <div className="dashboard-element element-2"></div>
              <div className="dashboard-element element-3"></div>
              <div className="dashboard-element element-4"></div>
            </div>
          </div>
          <div className="floating-shape shape-1"></div>
          <div className="floating-shape shape-2"></div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;