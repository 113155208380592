import React, { useState } from 'react';
import '../App.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('#home');

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setIsMenuOpen(false);
  };

  return (
    <div className="nav-container">
      <div className="mobile-menu-icon" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <div className={`menu-line ${isMenuOpen ? 'open' : ''}`}></div>
        <div className={`menu-line ${isMenuOpen ? 'open' : ''}`}></div>
        <div className={`menu-line ${isMenuOpen ? 'open' : ''}`}></div>
      </div>
      
      <div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
        <div className="nav-link">
          <a 
            className={activeLink === '#home' ? 'active-nav-link' : ''} 
            href="#home" 
            onClick={() => handleLinkClick('#home')}
          >
            home
            <span className="link-underline"></span>
          </a>
        </div>
        <div className="nav-link">
          <a 
            className={activeLink === '#services' ? 'active-nav-link' : ''} 
            href="#services" 
            onClick={() => handleLinkClick('#services')}
          >
            services
            <span className="link-underline"></span>
          </a>
        </div>
        {/* <div className="nav-link">
          <a 
            className={activeLink === '#about' ? 'active-nav-link' : ''} 
            href="#about" 
            onClick={() => handleLinkClick('#about')}
          >
            about
            <span className="link-underline"></span>
          </a>
        </div> */}
        <div className="nav-link">
          <a 
            className={activeLink === '#contact' ? 'active-nav-link' : ''} 
            href="#contact" 
            onClick={() => handleLinkClick('#contact')}
          >
            <a 
                href="https://site.vectorsoftwaresolutions.com/appointment" 
            >contact us
            </a>
            <span className="link-underline"></span>
          </a>
        </div>
        <div className="nav-link cta-link">
          <a 
            href="https://site.vectorsoftwaresolutions.com" 
            className="nav-cta"
            target="_blank" 
            rel="noopener noreferrer"
          >
            Get Started
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;