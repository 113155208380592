import React, { useState, useEffect } from 'react';
import Logo from './logo';
import Navbar from './navbar';

const Header = () => {
    const [activeLink, setActiveLink] = useState('#home');
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const handleLinkClick = (link) => {
        setActiveLink(link);
    };

    const handleScroll = () => {
        if (window.scrollY > lastScrollY && window.scrollY > 50) {
            // Scrolling down and has passed 50px
            setIsVisible(false);
        } else if (window.scrollY < lastScrollY) {
            // Scrolling up
            setIsVisible(true);
        }
        setLastScrollY(window.scrollY); // Update the last scroll position
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]); // Dependency array to track the previous scroll position

    return (
        <div className='navbar' style={{ top: isVisible ? '0' : '-100px' }}>
            <Logo />
            {/* <div className='nav-links'>
                <div className='nav-link'>
                    <a className={`nav-link ${activeLink === '#home' ? 'active-nav-link' : ''}`} href="#home" onClick={() => handleLinkClick('#home')}>home</a>
                </div>
                <div className='nav-link'>
                    <a className={`nav-link ${activeLink === '#services' ? 'active-nav-link' : ''}`} href="#services" onClick={() => handleLinkClick('#services')}>services</a>
                </div>
                <div className='nav-link'>
                    <a className={`nav-link ${activeLink === '#about' ? 'active-nav-link' : ''}`} href="#about" onClick={() => handleLinkClick('#about')}>about</a>
                </div>
                <div className='nav-link'>
                    <a className={`nav-link ${activeLink === '#contact' ? 'active-nav-link' : ''}`} href="#contact" onClick={() => handleLinkClick('#contact')}>contact us</a>
                </div>
            </div> */}
            <Navbar/>

        </div>
    );
};

export default Header;
