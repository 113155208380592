import React from 'react';
import './App.css';
import Header from './components/header';
import HeroSection from './components/hero-section';
import ServicesSection from './components/services-section';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCrjm93yNobfhUiMvAE3hi-XkRZFBG9FuQ",
  authDomain: "vector-software-solutions.firebaseapp.com",
  projectId: "vector-software-solutions",
  storageBucket: "vector-software-solutions.firebasestorage.app",
  messagingSenderId: "971369575892",
  appId: "1:971369575892:web:97307cc306f08d436e1241",
  measurementId: "G-5PPCDRC5MW"
};


function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <HeroSection />
        <ServicesSection />
        {/* Add other sections as needed */}
      </main>
      <footer className="footer">
        <div className="footer-container">
          <p>&copy; {new Date().getFullYear()} Vector Software Solutions. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default App;